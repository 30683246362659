import React, { useCallback, useState } from 'react';
import { useHash, useLocation, useWindowSize } from 'react-use';
import { useTranslation } from 'react-i18next';
import styled from '@material-ui/core/styles/styled';

import breakpoints from './const/breakpoints';
import Footer from './components/common/Footer';
import MobileHeader from './components/mobile/Header';
import DesktopHeader from './components/desktop/Header';

import Desktop from './views/Desktop';
import Mobile from './views/Mobile';

const Main = styled('main')(() => ({
  flex: '1 0 auto'
}));

const App = () => {
  const { width } = useWindowSize();
  const [hasVisibleModalUnsubscribe, setModalUnsubscribe] = useState<boolean>(false);
  const [hasVisibleModalSubscribe, setModalSubscribe] = useState<boolean>(false);
  const [curHash, setCurHash] = React.useState<string>();
  const hash = useHash();
  const { i18n } = useTranslation();
  const location = useLocation();
  const searchQuery = new URLSearchParams(location.search);
  const lang = searchQuery?.get('lang') || 'ru';

  React.useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, [lang]);
  React.useEffect(() => {
    if (curHash) {
      const section = document.querySelector(curHash);
      section?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [curHash]);

  React.useEffect(() => {
    if (hash?.[0] && hash?.[0] !== curHash) {
      if (hasVisibleModalSubscribe) {
        setModalSubscribe(false);
      }
      if (hasVisibleModalUnsubscribe) {
        setModalUnsubscribe(false);
      }
      setCurHash(hash?.[0]);
    }
  }, [hash]);

  const toggleModalInfo = useCallback(() => {
    setModalUnsubscribe(!hasVisibleModalUnsubscribe);
    document.body.style.overflow = hasVisibleModalUnsubscribe ? 'hidden' : 'auto';
  }, [hasVisibleModalUnsubscribe]);
  const toggleModalSubscribe = useCallback(() => {
    setModalSubscribe(!hasVisibleModalSubscribe);
    document.body.style.overflow = hasVisibleModalSubscribe ? 'hidden' : 'auto';
  }, [hasVisibleModalSubscribe]);
  const showLegalNotice = () => {
    window.open(`https://static.sz.kz/content/gamesCatalog/docs/ogo-udacha-${i18n.language}.pdf`, '_blank');
  };
  return (
    <>
      {
        width > breakpoints.values.md
          ? <DesktopHeader handlerSubscribe={ toggleModalSubscribe } handlerUnsubscribe={ toggleModalInfo } />
          : <MobileHeader handlerSubscribe={ toggleModalSubscribe } handlerUnsubscribe={ toggleModalInfo } />
      }
      <Main>
        {
          width > breakpoints.values.md
            ?
              <Desktop
                hasVisibleSubscribeModal={ hasVisibleModalSubscribe }
                hasVisibleUnsubscribeModal={ hasVisibleModalUnsubscribe }
                handlerSubscribe={ toggleModalSubscribe }
                handlerUnsubscribe={ toggleModalInfo }
                handlerLegalNotice={ showLegalNotice }
              />
            :
              <Mobile
                hasVisibleSubscribeModal={ hasVisibleModalSubscribe }
                hasVisibleUnsubscribeModal={ hasVisibleModalUnsubscribe }
                handlerSubscribe={ toggleModalSubscribe }
                handlerUnsubscribe={ toggleModalInfo }
                handlerLegalNotice={ showLegalNotice }
              />
        }
      </Main>
      <Footer />
    </>
  );
};

export default App;
