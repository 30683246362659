import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ComponentImgCup } from '../../assets/img/trophy.svg';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const Cup = () => {
  const { t } = useTranslation();
  return (
    <Box display={ 'flex' } flexDirection={ 'column' } alignItems={ 'center' } justifyContent={ 'center' }>
      <Typography variant="subtitle1" gutterBottom color={ 'textSecondary' }>
        <b>{ t('block.cup.title') }</b>
      </Typography>
      <Box display={ 'flex' } alignItems={ 'center' }>
        <ComponentImgCup style={ { marginLeft: -15, marginRight: 10 } }/>
        <Typography variant="h2" color="primary">
          { t('block.cup.head') }
        </Typography>
      </Box>
    </Box>
  );
};

export default Cup;
