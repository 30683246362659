import React from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'react-use';
import { useTheme } from '@material-ui/core';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Modal from '../../components/desktop/Modal';
import Banner from '../../components/desktop/Banner';
import { List, ListItem } from '../../components/common/Gifts';
import Cup from '../../components/common/Cup';
import Support from '../../components/common/Support';
import Rules from '../../components/common/Rules';
import Promo from '../../components/common/Promo';

const Desktop = ({ handlerSubscribe, handlerUnsubscribe, handlerLegalNotice, hasVisibleUnsubscribeModal, hasVisibleSubscribeModal }: {
  hasVisibleSubscribeModal: boolean;
  hasVisibleUnsubscribeModal: boolean;
  handlerSubscribe(): void;
  handlerUnsubscribe(): void;
  handlerLegalNotice(): void;
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { width } = useWindowSize();

  return (
    <>
      <section id={ 'banner' }>
        <Banner setOpenModalSubscribe={ handlerSubscribe } setOpenModalUnsubscribe={ handlerUnsubscribe }/>
      </section>
      <Container style={ { marginBottom: 32 } }>
        <Grid container spacing={ 4 }>
          <Grid item md={ 6 }>
            <section id={ 'gifts' } style={ { height: '100%', display: 'grid' } }>
              <Card>
                <div>
                  <CardHeader
                    style={ {
                      background: theme.palette.secondary.main
                    } }
                    title={ t('block.gifts.title') }
                  />
                  <CardContent
                    style={ {
                      margin: '0 15%',
                      padding: 40
                    } }
                  >
                    <Cup/>
                  </CardContent>
                  <CardActions
                    disableSpacing
                    style={ {
                      justifyContent: 'center',
                      alignItems: 'flex-start',
                      margin: '28px 25px',
                      padding: width < 1280 ? '0 62px' : 0,
                      minHeight: 350
                    } }
                  >
                    <List>
                      {
                        [1, 2, 3, 4, 5].map(n => (
                          <Box key={ n } marginLeft={ 4 } marginRight={ 4 }>
                            <ListItem isDesktop>
                              { t(`block.gifts.list.${n}`) }
                            </ListItem>
                          </Box>
                        ))
                      }
                    </List>
                    <List>
                      {
                        [6, 7, 8, 9].map(n => (
                          <Box key={ n } marginLeft={ 4 } marginRight={ 4 }>
                            <ListItem isDesktop>
                              { t(`block.gifts.list.${n}`) }
                            </ListItem>
                          </Box>
                        ))
                      }
                    </List>
                  </CardActions>
                </div>
                <CardActions disableSpacing style={ {
                  justifyContent: 'center',
                  margin: 0,
                  padding: '28px 40px',
                  background: '#EDEDED',
                  height: '100%',
                  maxHeight: 130,
                  display: 'flex'
                } }
                >
                  <Box display={ 'flex' } flexDirection={ 'column' } alignItems={ 'center' }>
                    <Button onClick={ handlerSubscribe } color={ 'secondary' } size={ 'small' } variant={ 'contained' }>
                      { t(`block.gifts.button`) }
                    </Button>
                    <a onClick={ handlerUnsubscribe } style={ { margin: '16px 10px', borderBottom: '1px dashed' } }>
                      { t(`block.gifts.link`) }
                    </a>
                  </Box>
                </CardActions>
              </Card>
            </section>
          </Grid>
          <Grid item md={ 6 }>
            <section id={ 'rules' } style={ { height: '100%', display: 'grid' } }>
              <Card>
                <div>
                  <CardHeader style={ { background: theme.palette.primary.main } } title={ t('block.rules.title') }/>
                  <CardContent style={ { margin: '0 40px' } }>
                    <Rules isDesktop/>
                  </CardContent>
                </div>
                <CardActions disableSpacing style={ { justifyContent: 'center', minHeight: 102, alignItems: 'flex-start' } }>
                  <Button onClick={ handlerLegalNotice } color={ 'primary' } size={ 'small' } variant={ 'contained' }>
                    { t(`block.rules.link`) }
                  </Button>
                  <a/>
                </CardActions>
              </Card>
            </section>
          </Grid>
        </Grid>
      </Container>
      <Container style={ { marginBottom: 32 } }>
        <Grid container spacing={ 4 }>
          <Grid item md={ 6 }>
            <section style={ { display: 'flex', width: '100%', height: '100%' } }>
              <Paper elevation={ 5 } style={ { width: '100%' } }>
                <Promo
                  isDesktop
                  styleWrapper={ {
                    height: '100%',
                    [theme.breakpoints.up(1220)]: {
                      minHeight: 341
                    },
                    [theme.breakpoints.down(1220)]: {
                      minHeight: 270
                    }
                  } }
                  styleContainer={ {
                    padding: '45px 65px',
                    height: '100%'
                  } }
                />
              </Paper>
            </section>
          </Grid>
          <Grid item md={ 6 }>
            <section id={ 'help' } style={ { display: 'flex', width: '100%', height: '100%' } }>
              <Paper elevation={ 5 } style={ { width: '100%' } }>
                <Box
                  display={ 'flex' }
                  flexDirection={ 'column' }
                  alignItems={ 'center' }
                  justifyContent={ 'center' }
                  padding={ 0 }
                  height={ '100%' }
                  minHeight={ 325 }
                >
                  <Typography color={ 'primary' } variant={ 'h4' }>
                    { t('block.support.help.title') }
                  </Typography>
                  <br/>
                  <Typography style={ { maxWidth: 330 } } align={ 'center' }>
                    { t('block.support.help.desc') }
                  </Typography>
                  <br/>
                  <Support/>
                </Box>
              </Paper>
            </section>
          </Grid>
        </Grid>
      </Container>
      <Modal localeKey={ 'modal.unsubscribe' } open={ hasVisibleUnsubscribeModal } handleClose={ handlerUnsubscribe } />
      <Modal localeKey={ 'modal.subscribe' } open={ hasVisibleSubscribeModal } handleClose={ handlerSubscribe } />
    </>
  );
};

export default Desktop;
