import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core';

import styled from '@material-ui/core/styles/styled';
import MuiModal from '@material-ui/core/Modal/Modal';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { Wrapper } from '../Header';
import { TPaletteBackground } from '../../../const/theme';
import { ReactComponent as IconCross } from '../../../assets/img/cross.svg';
import Support from '../../common/Support';
import Box from '@material-ui/core/Box';

export const Window = styled(MuiModal)({
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column'
});
export const InnerWrapper = styled('div')(({ theme }) => ({
  height: '100vh',
  backgroundColor: (theme.palette.background as TPaletteBackground).overlay,
  padding: '100px 32px'
}));
export const ModalFooter = styled('div')({
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
});
export const ModalContent = styled('div')(({ theme }) => ({
  background: theme.palette.common.white,
  padding: 25,
  boxSizing: 'border-box',
  boxShadow: '0px 6px 4px 0px #0000001F',
  marginBottom: 35,
  [theme.breakpoints.up('md')]: {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 640,
    minHeight: 'unset'
  }
}));

type TProps = {
  open: boolean;
  handleClose: () => void;
  localeKey: string;
};

const Modal: FC<TProps> = ({ open, handleClose, localeKey }) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  return (
    <Window open={ open } onClose={ handleClose }>
      <React.Fragment>
        <Wrapper>
          <Box
            display={ 'flex' }
            alignItems={ 'center' }
            position={ 'relative' }
            height={ 78 }
            justifyContent={ 'space-between' }
            padding={ '0 32px' }
            width={ '100%' }
          >
            <Typography color={ 'primary' } variant={ 'h5' }>{ t(`${localeKey}.head`) }</Typography>
            <IconCross style={ { cursor: 'pointer' } } onClick={ handleClose } />
          </Box>
        </Wrapper>
        <InnerWrapper>
          <ModalContent>
            { i18n.exists(`${localeKey}.title`) && <Typography color={ 'primary' } variant={ 'h6' }>{ t(`${localeKey}.title`) }</Typography> }
            <Typography
              color={ 'textSecondary' }
              dangerouslySetInnerHTML={ { __html: t(`${localeKey}.desc`, {
              price: '100',
              ussd: '88',
              phone: `<a href="${ 'tel:88000809351' }" style="color: ${theme.palette.primary.main}; text-decoration: none">8 800 080 93 51</a>`,
              h6Style: `
              color: ${theme.palette.primary.main};
              max-width: 225px;
              margin-top: 18px;
              margin-bottom: 18px;
              font-size: 16px;
              line-height: 22px;
              `,
              pStyle: `
              ${i18n.exists(`${localeKey}.title`) ? 'margin-top: 18px;' : ''}
              margin-bottom: 18px;
              font-size: 14px;
              line-height: 20px;
              `
              })} }
            />
          </ModalContent>
          <ModalFooter>
            <Button
              size={ 'small' }
              onClick={ handleClose }
              color="primary"
              variant="contained"
              disableElevation
              style={ {
                marginBottom: 25
              } }
            >
              { t(`${localeKey}.button`) }
            </Button>
            <Support />
          </ModalFooter>
        </InnerWrapper>
      </React.Fragment>
    </Window>
  );
};

export default Modal;
