import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import './i18n';
import App from './App';
import theme from './const/theme';
import './assets/index.css';

ReactDOM.render(
  <ThemeProvider theme={ theme }>
    <App />
  </ThemeProvider>
  ,
  document.getElementById('root')
);
