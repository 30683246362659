import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHash } from 'react-use';

import MenuItem from '@material-ui/core/MenuItem';
import Container from '@material-ui/core/Container';
import Select from '@material-ui/core/Select';
import styled from '@material-ui/core/styles/styled';

import { ReactComponent as LogoSvg } from '../../../assets/img/logo.svg';

import theme, { TCustomTheme } from '../../../const/theme';

export const Wrapper = styled('div')(({ style }: { style?: any }) => ({
  backgroundColor: style?.backgroundColor || '#ffffff',
  boxShadow: style?.boxShadow || '0px 6px 4px 0px #0000001F',
  position: style?.position || 'fixed',
  zIndex: style?.zIndex || 1301,
  width: style?.width || '100%',
  display: style?.display || 'flex',
  alignItems: style?.alignItems || 'center',
  height: style?.height || 117,
  ...style
}));

const TopBar = styled(
  'div'
)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}));

const Logo = styled(LogoSvg)(({ theme: t }: { theme: TCustomTheme }) => ({
  [t.breakpoints.up('md')]: {
    width: 257,
    height: 'auto'
  }
}));

const Header = ({ handlerSubscribe, handlerUnsubscribe }: { handlerSubscribe(): void; handlerUnsubscribe(): void; }) => {
  const { t, i18n } = useTranslation();
  const hash = useHash();
  const [curHash, setCurHash] = React.useState<string>();

  const getHandler = (handler: string) => {
    if (handler === 'subscribe') return handlerSubscribe;
    if (handler === 'unsubscribe') return handlerUnsubscribe;
  };
  const onChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    i18n.changeLanguage(e.target.value as string);
  };

  React.useEffect(() => {
    if (hash?.[0] && hash?.[0] !== curHash) {
      setCurHash(hash?.[0]);
    }
  }, [hash?.[0]]);

  return (
    <>
      <header style={ { height: 117 } }>
        <Wrapper>
          <Container>
            <TopBar>
              <a href={ '/#' } style={ { display: 'flex', justifyContent: 'center' } }>
                <Logo />
              </a>
              {
                [1, 2, 3, 4, 5].map(n => (
                  <MenuItem
                    onClick={
                      i18n.exists(`block.header.menu.${n}.handler`)
                        ? getHandler(t(`block.header.menu.${n}.handler`))
                        : () => {}
                    }
                    key={ n }
                    style={ {
                      color: theme.palette.text.disabled
                    } }
                  >
                    {
                      i18n.exists(`block.header.menu.${n}.link`)
                        ?
                          <a
                            style={ {
                              color: curHash === `#${t(`block.header.menu.${n}.link`)}` ? theme.palette.primary.main : theme.palette.text.disabled,
                              fontWeight: curHash === `#${t(`block.header.menu.${n}.link`)}` ? 700 : 300,
                              textDecoration: 'none'
                            } }
                            href={ `#${t(`block.header.menu.${n}.link`)}` }
                          >
                            { t(`block.header.menu.${n}.title`) }
                          </a>
                        :
                          i18n.exists(`block.header.menu.${n}.handler`)
                            ? t(`block.header.menu.${n}.title`)
                            : null
                    }
                  </MenuItem>
                ))
              }
              <Select
                disableUnderline
                id={ 'language' }
                value={ i18n.language }
                onChange={ onChange }
                MenuProps={ {
                  style: {
                    zIndex: 1302
                  }
                } }
              >
                <MenuItem value="ru">
                  { t('ru') }
                </MenuItem>
                <MenuItem value="kz">
                  { t('kz') }
                </MenuItem>
              </Select>
            </TopBar>
          </Container>
        </Wrapper>
      </header>
    </>
  );
};

export default Header;
