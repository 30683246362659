import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core';

import styled from '@material-ui/core/styles/styled';
import MuiModal from '@material-ui/core/Modal/Modal';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { TPaletteBackground } from '../../../const/theme';
import Support from '../../common/Support';

MuiModal.defaultProps = {
  style: {
    zIndex: 1302
  }
};
export const Window = styled(MuiModal)({
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute'
});
export const InnerWrapper = styled('div')(({ theme }) => ({
  maxWidth: 760,
  margin: '150px auto',
  backgroundColor: (theme.palette.background as TPaletteBackground).overlay,
  padding: 32
}));
export const ModalFooter = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'row',
  justifyContent: 'start'
});
export const ModalContent = styled('div')(({ theme }) => ({
  background: theme.palette.common.white,
  padding: 25,
  boxSizing: 'border-box',
  boxShadow: '0px 6px 4px 0px #0000001F',
  marginBottom: 35
}));

type TProps = {
  open: boolean;
  handleClose: () => void;
  localeKey: string;
};

const Modal: FC<TProps> = ({ open, handleClose, localeKey }) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  return (
    <Window open={ open } onClose={ handleClose }>
      <React.Fragment>
        <InnerWrapper>
          <Box display={ 'flex' } alignItems={ 'center' } justifyContent={ 'space-between' } marginBottom={ 3 }>
            <Typography
              color={ 'primary' }
              variant={ 'h4' }
            >
              { t(`${localeKey}.head`) }
            </Typography>
            <a onClick={ handleClose }>
              <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="26" cy="26" r="26" fill="#B4D335"/>
                <path d="M26.8535 29.9727L26.5 29.6191L26.1464 29.9727L14.8838 41.2352L11.7642 38.1156L23.0268 26.8531L23.3804 26.4995L23.0268 26.1459L11.7642 14.8834L14.8838 11.7637L26.1464 23.0263L26.5 23.3799L26.8535 23.0263L38.1161 11.7637L41.2357 14.8834L29.9732 26.1459L29.6196 26.4995L29.9732 26.8531L41.2357 38.1156L38.1161 41.2352L26.8535 29.9727Z" fill="white" stroke="#B4D335"/>
              </svg>
            </a>
          </Box>
          <ModalContent>
            { i18n.exists(`${localeKey}.title`) &&
              <Typography
                color={ 'primary' }
                variant={ 'h6' }
                style={ {
                  fontSize: 20
                } }
              >
                { t(`${localeKey}.title`) }
              </Typography> }
            <Typography
              color={ 'textSecondary' }
              dangerouslySetInnerHTML={ { __html: t(`${localeKey}.desc`, {
              price: '100',
              ussd: '88',
              phone: `<a href="${ 'tel:88000809351' }" style="color: ${theme.palette.primary.main}; text-decoration: none">8 800 080 93 51</a>`,
              h6Style: `
              color: ${theme.palette.primary.main};
              margin-top: 18px;
              margin-bottom: 18px;
              font-size: 18px;
              line-height: 25px;
              `,
              pStyle: `
              ${i18n.exists(`${localeKey}.title`) ? 'margin-top: 18px;' : ''}
              margin-bottom: 18px;
              font-size: 16.5px;
              line-height: 25px;
              `
              })} }
            />
          </ModalContent>
          <ModalFooter>
            <Button
              size={ 'small' }
              onClick={ handleClose }
              color="primary"
              variant="contained"
              disableElevation
              style={ { marginRight: 35, maxWidth: 225, minWidth: 225 } }
            >
              { t(`${localeKey}.button`) }
            </Button>
            <Support />
          </ModalFooter>
        </InnerWrapper>
      </React.Fragment>
    </Window>
  );
};

export default Modal;
