import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core';

import { ReactComponent as IconSupport } from '../../assets/img/support.svg';
import Typography from '@material-ui/core/Typography';

const Support = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <>
      <IconSupport style={ { marginBottom: 8 } }/>
      <Typography
        align={ 'center' }
        color={ 'textSecondary' }
        dangerouslySetInnerHTML={ { __html: t('block.support.title', {
          phone: `<a href="${ 'tel:88000809351' }" style="color: ${theme.palette.primary.main}; font-weight: bold;">8 800 080 93 51</a>`
        })} }
      />
    </>
  );
};

export default Support;
