import React from 'react';
import { useTheme } from '@material-ui/core';

type TProps = { style?: React.CSSProperties };

const C18Plus: React.FC<TProps> = ({ style }) => {
  const theme = useTheme();
  return (
    <div
      style={ {
        background: style?.background || theme.palette.common.white,
        opacity: style?.opacity || 0.7,
        borderRadius: style?.borderRadius || '50%',
        color: style?.color || theme.palette.text.disabled,
        alignItems: style?.alignItems || 'center',
        display: style?.display || 'flex',
        justifyContent: style?.justifyContent || 'center',
        width: style?.width || 35,
        height: style?.height || 35,
        ...style
      } }
    >
      18+
    </div>
  );
};

export default C18Plus;
