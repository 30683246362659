import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useTheme } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import C18Plus from '../../common/icons/18+';

import { TCustomTheme, TPaletteBackground } from '../../../const/theme';
import imgBanner from '../../../assets/img/banner.webp';
import imgLogoLottery from '../../../assets/img/logo-lottery.webp';
import Typography from '@material-ui/core/Typography';

const Block = styled('div')(({ theme }: { theme: TCustomTheme }) => ({
  boxShadow: '0px 6px 4px 0px #0000001F',
  position: 'relative',
  zIndex: 5
}));

const Wrapper = styled('div')(({ theme }: { theme: TCustomTheme }) => ({
  color: theme.palette.common.white,
  fontSize: 15
}));

type TProps = {
  setOpenModalSubscribe: () => void;
};

const Banner: FC<TProps> = ({ setOpenModalSubscribe }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Block>
      <Container disableGutters>
        <Wrapper>
          <Container
            style={ {
              paddingTop: 26,
              background: (theme.palette.background as TPaletteBackground).banner
            } }
          >
            <Box display={ 'flex' }>
              <img
                style={ {
                  maxWidth: 136,
                  marginRight: 26
                } }
                src={ imgLogoLottery }
              />
              <Typography
                variant={ 'h6' }
                style={ {
                  fontWeight: 400,
                  maxWidth: 265,
                  fontSize: 14
                } }
                dangerouslySetInnerHTML={ { __html: t('block.banner.title') } }
              />
            </Box>
          </Container>
          <div
            style={ {
              padding: '27px 0',
              background: (theme.palette.background as TPaletteBackground).banner,
              height: '412px',
              backgroundPosition: 'left calc(50% - 15px) center',
              backgroundImage: `url(${imgBanner})`,
              backgroundSize: 'cover',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'end',
              alignItems: 'center',
              position: 'relative'
            } }
          >
            <div>
              <Button
                size={ 'large' }
                onClick={ setOpenModalSubscribe }
                color="primary"
                variant="contained"
                disableElevation
              >
                { t('block.banner.button') }
              </Button>
              <C18Plus
                style={ {
                  position: 'absolute',
                  right: 35,
                  bottom: 35
                } }
              />
            </div>
          </div>
        </Wrapper>
      </Container>
    </Block>
  );
};

export default Banner;
