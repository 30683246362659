import React from 'react';
import { useTranslation } from 'react-i18next';
import { TCustomTheme } from '../../../const/theme';
import { ReactComponent as MoneyIcon } from '../../../assets/img/money-stack.svg';

import styled from '@material-ui/core/styles/styled';
import Button from '@material-ui/core/Button';

export const List = styled('ul')({
  listStyle: 'none',
  marginLeft: -4.25,
  padding: 0,
  marginTop: 0,
  marginBottom: 0
});

const StyledLi = styled('li')({
  display: 'flex',
  alignItems: 'center',
  paddingTop: 14,
  paddingBottom: 14
});

const ListText = styled('span')(({ theme, isDesktop }: { theme: TCustomTheme; isDesktop?: boolean; }) => ({
  paddingLeft: 10,
  fontSize: isDesktop ? 18 : 14,
  color: theme.palette.text.disabled,
  margin: isDesktop ? '6px 0px' : '3px 0',
  [theme.breakpoints.down(1440)]: {
    fontSize: isDesktop ? 16 : 14
  },
  [theme.breakpoints.down(1324)]: {
    fontSize: isDesktop ? 15 : 14
  }
}));

const StyledMoneyIcon = styled(MoneyIcon)({
  width: 24,
  height: 24
});

export const ListItem = ({ children, isDesktop }: { isDesktop?: boolean; } & React.PropsWithChildren) => (
  <StyledLi>
    <StyledMoneyIcon />
    <ListText isDesktop={ isDesktop }>
      { children }
    </ListText>
  </StyledLi>
);

const Gifts: React.FC<{ onClickButton(): void; onClickLink(): void; isDesktop?: boolean; }> = ({
  onClickButton,
  onClickLink,
  isDesktop = false
}) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <List>
        {
          [1, 2, 3, 4, 5, 6, 7, 8, 9].map(n => (
            <ListItem key={ n } isDesktop={ isDesktop }>
              { t(`block.gifts.list.${n}`) }
            </ListItem>
          ))
        }
      </List>
      <Button
        onClick={ onClickButton }
        color={ 'primary' }
        size={ 'small' }
        variant={ 'contained' }
        style={ { marginTop: 25 } }
      >
        { t(`block.gifts.button`) }
      </Button>
      <a onClick={ onClickLink } style={ { margin: '16px 10px', borderBottom: '1px dashed' } }>
        { t(`block.gifts.link`) }
      </a>
    </React.Fragment>
  );
};

export default Gifts;
