import createTheme, { Theme } from '@material-ui/core/styles/createTheme';
import { TypeBackground } from '@material-ui/core/styles/createPalette';
import breakpoints from './breakpoints';

export type TCustomTheme = {
  colors: Record<string, string>
} & Theme;

export type TPaletteBackground = TypeBackground & { banner?: string; overlay?: string; dark?: string; };

let theme = createTheme();
theme = createTheme({
  palette: {
    text: {
      primary: '#000000',
      secondary: '#ffffff',
      disabled: '#6C6D70'
    },
    primary: {
      main: '#652E87',
      light: '#F2CDDE'
    },
    background: {
      default: '#ffffff',
      paper: '#C4C4C4',
      banner: '#BE72E0',
      overlay: '#f7f7f7',
      dark: '#717171'
    } as TPaletteBackground,
    secondary: {
      main: '#B4D335'
    },
    divider: '#B3B3B3'
  },
  breakpoints,
  typography: {
    fontFamily: '\'Pragmatica\', sans-serif',
    h1: {
      fontWeight: 900,
      fontSize: 30,
      textTransform: 'uppercase'
    },
    h2: {
      fontWeight: 900,
      fontSize: 30,
      textTransform: 'uppercase'
    },
    h3: {
      fontWeight: 700,
      fontSize: 24
    },
    h4: {
      fontWeight: 400,
      fontSize:  24
    },
    h5: {
      fontSize: 21,
      fontWeight: 700
    },
    h6: {
      fontSize: 18,
      fontWeight: 700
    },
    body1: {
      fontSize: 16
    }
  },
  overrides: {
    MuiTypography: {
      colorTextSecondary: {
        color: '#6C6D70'
      }
    },
    MuiCard: {
      root: {
        position: 'relative',
        background: '#F7F7F7',
        boxShadow: '0px 6px 4px 0px rgba(0, 0, 0, 0.12)',
        border: `0.5px solid #C4C4C4`,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }
    },
    MuiCardHeader: {
      root: {
        position: 'relative',
        padding: 0,
        boxShadow: '0px 6px 4px 0px rgba(0, 0, 0, 0.12)',
        zIndex: 4
      },
      title: {
        fontWeight: 700,
        color: '#ffffff',
        display: 'flex',
        alignItems: 'end',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
          height: 60,
          fontSize: 18,
          lineHeight: '25px',
          paddingBottom: 22
        },
        [theme.breakpoints.up('md')]: {
          fontSize: 32,
          height: 122,
          lineHeight: '100px'
        }
      }
    },
    MuiCardContent: {
      root: {
        position: 'relative',
        margin: '0 16px 16px 16px',
        backgroundColor: '#ffffff',
        padding: 32,
        zIndex: 3,
        border: '0.5px solid #C4C4C4',
        [theme.breakpoints.up('md')]: {
          boxShadow: '0px 6px 4px 0px rgba(0, 0, 0, 0.12)'
        }
      }
    },
    MuiCardActions: {
      root: {
        margin: '28px 40px',
        [theme.breakpoints.down(1024)]: {
          flexDirection: 'column'
        }
      }
    },
    MuiPaper: {
      root: {
        backgroundColor: '#ffffff'
      },
      elevation5: {
        boxShadow: '0px 6px 4px 0px rgba(0, 0, 0, 0.12)',
        border: '0.5px solid #C4C4C4'
      }
    },
    MuiSelect: {
      select: {
        color: '#6C6D70',
        '&:focus': {
          backgroundColor: 'transparent'
        }
      }
    },
    MuiContainer: {
      root: {
        paddingLeft: 20,
        paddingRight: 20
      }
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        borderRadius: 50
      },
      containedSecondary: {
        color: '#ffffff'
      },
      containedSizeSmall: {
        minWidth: 250,
        height: 45,
        fontSize: 16,
        fontWeight: 700,
        boxShadow: '0px 4px 4px 0px #9D7B2540',
        [theme.breakpoints.up('md')]: {
          minWidth: 350,
          height: 60,
          fontSize: 20
        }
      },
      containedSizeLarge: {
        border: '4px solid #F2CDDE',
        height: 50,
        padding: '16px 45px',
        fontSize: 16,
        fontWeight: 700,
        [theme.breakpoints.up('md')]: {
          height: 60,
          fontSize: 20
        }
      }
    }
  }
});

theme.typography.h3[theme.breakpoints.up('md')] = {
  fontSize: 32,
  fontWeight: 700
};
theme.typography.h4[theme.breakpoints.up('md')] = {
  fontSize: 32,
  fontWeight: 700
};

const customTheme: TCustomTheme = {
  ...theme,
  colors: {
    grey: '#a1a1a1',
    dark: '#282828'
  }
};

export default customTheme;
