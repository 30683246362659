import React from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'react-use';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import styled from '@material-ui/core/styles/styled';

const StyledBox = styled(Box)(({ theme }) => ({
  '&:last-child > div': {
    color: theme.palette.secondary.main
  }
}));

const Li = styled(Box)(({ theme }) => ({
  marginRight: 20,
  marginLeft: -12,
  display: 'flex',
  alignItems: 'center',
  fontSize: 32,
  color: theme.palette.divider,
  fontFamily: '\'PragmaticaBold\', sans-serif',
  '&:last-child': {
    color: theme.palette.secondary.main
  },
  '&:before': {
    content: '""',
    width: 23,
    border: '1.5px solid',
    transform: 'rotate(-45deg)',
    display: 'block',
    marginBottom: 12,
    position: 'relative',
    top: -14,
    left: 10
  },
  '&:after': {
    content: '""',
    width: 23,
    border: '1.5px solid',
    transform: 'rotate(-45deg)',
    display: 'block',
    marginTop: 12,
    position: 'relative',
    bottom: -9,
    right: 9
  }
}));

const Rules = ({ isDesktop = false }: { isDesktop?: boolean; }) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  return (
    <Box display={ 'flex' } flexDirection={ 'column' }>
      {
        [1, 2, 3, 4, 5].map(n => (
          <StyledBox
            display={ 'flex' }
            flexDirection={ 'row' }
            key={ n }
            marginBottom={ 2 }
            marginTop={ 2 }
            position={ 'relative' }
          >
            <Li>
              { n }
            </Li>
            <Typography
              variant={ 'subtitle1' }
              color={ 'textSecondary' }
              style={ {
                fontSize: isDesktop ? (width < 1324 ? 15 : 16) : 14
              } }
            >
              { t(`block.rules.list.${n}`) }
            </Typography>
          </StyledBox>
        ))
      }
    </Box>
  );
};

export default Rules;
