import React from 'react';
import { useTranslation } from 'react-i18next';

import { TCustomTheme } from '../../../const/theme';
import imgBackground from '../../../assets/img/promo.webp';
import { ReactComponent as ComponentImgLogo } from '../../../assets/img/sz.svg';
import { ReactComponent as ComponentImgOverlay } from '../../../assets/img/overlay.svg';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import styled from '@material-ui/core/styles/styled';

// @ts-ignore
const Wrapper = styled(Box)(({ theme, style }) => ({
  background: `url(${imgBackground})`,
  backgroundSize: 'contain',
  backgroundPosition: '94.65% center',
  backgroundRepeat: 'no-repeat',
  minHeight: 300,
  zIndex: 2,
  position: 'relative',
  ...style,
  [theme.breakpoints.down(530)]: {
    backgroundSize: '244.95px 266px',
    minHeight: 255
  },
  [theme.breakpoints.down(400)]: {
    backgroundSize: '195px 212px',
    minHeight: 205
  }
}));

const Overlay = styled(ComponentImgOverlay)(({ theme, isDesktop }: { theme: TCustomTheme, isDesktop: boolean; }) => ({
  zIndex: 1,
  position: 'absolute',
  bottom: 0,
  right: 0,
  width: '100%',
  height: '100%',
  maxHeight: 275,
  maxWidth: 380,
  borderBottomRightRadius: isDesktop ? 0 : 120,
  overflow: 'hidden',
  [theme.breakpoints.down(1240)]: {
    maxWidth: 320,
    maxHeight: 230
  },
  [theme.breakpoints.down(530)]: {
    maxWidth: 260,
    borderBottomRightRadius: 94
  },
  [theme.breakpoints.down(400)]: {
    maxWidth: 230,
    maxHeight: 165
  }
}));

const Promo = ({ styleWrapper, styleContainer, isDesktop = false }: {
  styleWrapper?: React.CSSProperties,
  styleContainer?: React.CSSProperties,
  isDesktop?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <Box style={ { position: 'relative' } } height={ '100%' }>
      <Wrapper style={ styleWrapper }>
        <Container style={ styleContainer }>
          <ComponentImgLogo/>
          <Box display={ 'flex' } maxWidth={ isDesktop ? 190 : 164 }>
            <Typography
              color={ 'textSecondary' }
              variant={ 'subtitle2' }
              align={ 'center' }
              dangerouslySetInnerHTML={ { __html: t('slogan') } }
              style={ {
                fontSize: isDesktop ? 14 : 10
              } }
            />
          </Box>
        </Container>
      </Wrapper>
      <Overlay isDesktop={ isDesktop }/>
    </Box>
  );
};

export default Promo;
