import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHash } from 'react-use';

import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import Container from '@material-ui/core/Container';
import Select from '@material-ui/core/Select';
import styled from '@material-ui/core/styles/styled';

import { ReactComponent as BurgerSvg } from '../../../assets/img/burger.svg';
import { ReactComponent as LogoSvg } from '../../../assets/img/logo.svg';

import theme, { TCustomTheme } from '../../../const/theme';
import { Window, InnerWrapper, ModalFooter } from '../Modal';
import Support from '../../common/Support';

export const Wrapper = styled('div')(({ style }: { style?: any }) => ({
  backgroundColor: style?.backgroundColor || '#ffffff',
  boxShadow: style?.boxShadow || '0px 6px 4px 0px #0000001F',
  position: style?.position || 'fixed',
  zIndex: style?.zIndex || 1301,
  width: style?.width || '100%',
  display: style?.display || 'flex',
  alignItems: style?.alignItems || 'center',
  height: style?.height || 63,
  ...style
}));

const TopBar = styled(
  'div'
)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}));

const Logo = styled(LogoSvg)(({ theme: t }: { theme: TCustomTheme }) => ({
  [t.breakpoints.up('md')]: {
    width: 120,
    height: 'auto'
  }
}));

const Burger = styled(BurgerSvg)(({ theme: t }: { theme: TCustomTheme }) => ({
  [t.breakpoints.up('md')]: {
    width: 120,
    height: 'auto'
  }
}));

const Header = ({ handlerSubscribe, handlerUnsubscribe }: { handlerSubscribe(): void; handlerUnsubscribe(): void; }) => {
  const [hasVisibleMenu, setHasVisibleMenu] = React.useState<boolean>();
  const { t, i18n } = useTranslation();
  const hash = useHash();
  const [curHash, setCurHash] = React.useState<string>();

  const getHandler = (handler: string) => {
    if (handler === 'subscribe') {
      return () => {
        setHasVisibleMenu(false);
        handlerSubscribe();
      };
    }
    if (handler === 'unsubscribe') {
      return () => {
        setHasVisibleMenu(false);
        handlerUnsubscribe();
      };
    }
  };
  const onChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    i18n.changeLanguage(e.target.value as string);
  };
  const toggleMenu = () => setHasVisibleMenu(!hasVisibleMenu);

  React.useEffect(() => {
    if (hash?.[0] && hash?.[0] !== curHash) {
      setCurHash(hash?.[0]);
      setHasVisibleMenu(false);
    }
  }, [hash?.[0]]);

  return (
    <>
      <header style={ { height: 63 } }>
        <Wrapper>
          <Container>
            <TopBar>
              <Burger onClick={ toggleMenu }/>
              <a href={ '/#' } style={ { display: 'flex', justifyContent: 'center' } }>
                <Logo />
              </a>
              <Select
                disableUnderline
                id={ 'language' }
                value={ i18n.language }
                onChange={ onChange }
                MenuProps={ {
                  style: {
                    zIndex: 1302
                  }
                } }
              >
                <MenuItem value="ru">
                  { t('ru') }
                </MenuItem>
                <MenuItem value="kz">
                  { t('kz') }
                </MenuItem>
              </Select>
            </TopBar>
          </Container>
        </Wrapper>
      </header>
      {
        hasVisibleMenu
          ?
            <Window
              hideBackdrop
              open={ hasVisibleMenu }
              onClose={ toggleMenu }
              style={ {
                top: -1,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0
              } }
            >
              <InnerWrapper>
                <ModalFooter>
                  <Box width={ '100%' } marginBottom={ 5 }>
                    {
                      [1, 2, 3, 4, 5].map(n => (
                        <MenuItem
                          onClick={ i18n.exists(`block.header.menu.${n}.handler`)
                            ? getHandler(t(`block.header.menu.${n}.handler`))
                            : () => {}
                          }
                          key={ n }
                          style={ {
                            borderBottom: `1px solid ${theme.palette.divider}`,
                            color: theme.palette.text.disabled
                          } }
                        >
                          {
                            i18n.exists(`block.header.menu.${n}.link`)
                              ?
                                <a
                                  style={ {
                                    color: curHash === `#${t(`block.header.menu.${n}.link`)}` ? theme.palette.primary.main : theme.palette.text.disabled,
                                    fontWeight: curHash === `#${t(`block.header.menu.${n}.link`)}` ? 700 : 300,
                                    textDecoration: 'none'
                                } }
                                  href={ `#${t(`block.header.menu.${n}.link`)}` }
                                >
                                  { t(`block.header.menu.${n}.title`) }
                                </a>
                              : i18n.exists(`block.header.menu.${n}.handler`)
                                ? t(`block.header.menu.${n}.title`)
                                : null
                          }
                        </MenuItem>
                      ))
                    }
                  </Box>
                  <Support/>
                </ModalFooter>
              </InnerWrapper>
            </Window>
          : null
      }
    </>
  );
};

export default Header;
